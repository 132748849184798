@import "src/scss/module";

.ReviewsCarousels {
  position: relative;

  .bg_1 {
    bottom: 0;
    left: 0;
  }

  .bg_2 {
    bottom: 7rem;
    right: 0;
  }

  :global {
    .invisible-carousel {
      visibility: hidden;
      height: 0;
      overflow: hidden;
    }

    .icon {
      margin-bottom: -20px;
      z-index: #{$zindex-popover};
    }

    .btn-group {
      background-color: #{$navy};

      & :not(.btn-check:first-child)+.btn {
        margin-left: 2px;
      }

      &>.btn {
        background-color: #{$navy};
        color: #{$white};

        &:not(:last-child):not(.dropdown-toggle) {
          border-top-right-radius: var(--bs-border-radius);
          border-bottom-right-radius: var(--bs-border-radius);
        }

        &:not(.btn-check)+.btn {
          border-top-left-radius: var(--bs-border-radius);
          border-bottom-left-radius: var(--bs-border-radius);
        }

        &.active {
          background-color: #{$white};
          color: #{$navy};
        }
      }
    }

    .swiper {
      --swiper-pagination-bullet-size: 8px;
      --swiper-pagination-bullet-inactive-color: transparent;
      --swiper-pagination-bullet-border-radius: 0;
      --swiper-pagination-bullet-inactive-opacity: 1;
      padding-bottom: 3rem;
    }

    .swiper-pagination-bullet {
      border: 1px solid #{$navy};
      transform: scale(1) !important;
    }

    .swiper-slide {
      height: auto;
      scale: 0.9;
      transition: scale 0.3s;
    }

    .swiper-slide-active {
      scale: 1;
    }
  }
}